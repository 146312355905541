<template>
  <div>

    <!-- Static sidebar for desktop -->
    <Sidebar @close-sidebar="sidebarOpen = false" :sidebar-open="sidebarOpen"/>

    <div class="flex flex-1 flex-col md:pl-64">
      <Header @open-sidebar="sidebarOpen = true" />

      <main class="flex-1 h-full bg-gray-50 p-4 pt-10 md:p-14">
          <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Layout/Sidebar";
import Header from "../components/Layout/Header";

export default {
  components: {Header, Sidebar},
  data()
  {
    return {
      sidebarOpen: false,
    }
  },

  async mounted()
  {
    let user = await this.$sanctumAuth.getUser();

    Intercom('update', {
      email: user.email,
      name: user.name,
    });
  }
}
</script>


